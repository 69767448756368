module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"concord","accessToken":"MC5YbUFFM3hJQUFDSUFQaEFm.I--_ve-_ve-_ve-_vUzvv73vv717Y0Dvv70j77-9Zu-_ve-_ve-_ve-_ve-_ve-_vQ8vXirvv707cAbvv70D77-9","path":"/preview","previews":true,"pages":[{"type":"Services","match":"/services/:uid","path":"/services/entry","component":"/Users/bendesilva/Sites/concord-gatsby/src/templates/services.jsx"},{"type":"News_articles","match":"/news-media/:uid","path":"/news-media/entry","component":"/Users/bendesilva/Sites/concord-gatsby/src/templates/news-articles.jsx"},{"type":"Case_studies","match":"/case-studies/:uid","path":"/case-studies/entry","component":"/Users/bendesilva/Sites/concord-gatsby/src/templates/case-studies.jsx"},{"type":"Policies","match":"/policies/:uid","path":"/policies/entry","component":"/Users/bendesilva/Sites/concord-gatsby/src/templates/policies.jsx"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WRFSRBW","includeInDevelopment":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
