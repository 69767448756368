// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../../../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-commodities-jsx": () => import("./../../../src/pages/commodities.jsx" /* webpackChunkName: "component---src-pages-commodities-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-investor-relations-jsx": () => import("./../../../src/pages/investor-relations.jsx" /* webpackChunkName: "component---src-pages-investor-relations-jsx" */),
  "component---src-pages-news-media-jsx": () => import("./../../../src/pages/news-media.jsx" /* webpackChunkName: "component---src-pages-news-media-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-past-resolutions-jsx": () => import("./../../../src/pages/past-resolutions.jsx" /* webpackChunkName: "component---src-pages-past-resolutions-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-sustainability-jsx": () => import("./../../../src/pages/sustainability.jsx" /* webpackChunkName: "component---src-pages-sustainability-jsx" */),
  "component---src-pages-terms-conditions-jsx": () => import("./../../../src/pages/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-jsx" */),
  "component---src-templates-case-studies-jsx": () => import("./../../../src/templates/case-studies.jsx" /* webpackChunkName: "component---src-templates-case-studies-jsx" */),
  "component---src-templates-news-articles-jsx": () => import("./../../../src/templates/news-articles.jsx" /* webpackChunkName: "component---src-templates-news-articles-jsx" */),
  "component---src-templates-policies-jsx": () => import("./../../../src/templates/policies.jsx" /* webpackChunkName: "component---src-templates-policies-jsx" */),
  "component---src-templates-services-jsx": () => import("./../../../src/templates/services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */)
}

